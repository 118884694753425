<template>
  <div>
    <s-search :queryItems="queryItems" @fetchData="fetchUserAttend"></s-search>
    <div class="StatisticsNum">
      <span>姓名：</span><span> admin</span>
      <span class="StatisticsNum_left">考勤总天数: </span><span> 360天</span>
      <span class="StatisticsNum_left">累计总时长: </span
      ><span> 24小时60分钟60秒</span>
    </div>
    <div class="table-button">
      <a-button type="plain" icon="cloud-download" @click="handleExport"
        >导出</a-button
      >
      <a-button type="plain" icon="redo" @click="$refs.table.refresh(true)"
        >刷新</a-button
      >
      <columns-set
        tableName="WorkerAttend"
        :columns="columns"
        @changeColumns="changeColumns"
      ></columns-set>
    </div>

    <a-table
      ref="table"
      size="default"
      bordered
      rowKey="key"
      :columns="tableColumns"
      :data-source="dataSource"
      :loading="loading"
      :pagination="false"
      :scroll="{ x: 800 }"
    >
      <!-- 新增工地名称 -->
      <span slot="ws_name" slot-scope="text">
        <a-tooltip placement="topLeft">
          <template slot="title">
            <span>{{ text }}</span>
          </template>
          <span>{{ text.replace(/直辖市|-北京|-天津/g, "") }}</span>
        </a-tooltip>
      </span>

      <span slot="action" slot-scope="text, record"
        ><a @click="MingXi('MingXi', '工地打卡列表', record)">明细</a></span
      >
    </a-table>
    <a-pagination
      size="middle"
      v-model="current"
      :pageSize="pageSize"
      @change="pageNumberChange"
      @showSizeChange="sizeChange"
      :pageSizeOptions="sizeList"
      :total="total"
      showSizeChanger
      style="margin: 20px 0; text-align: right"
    />

    <!-- <a-modal
      class="tips-modal"
      title="提示"
      okText="确定"
      cancelText="取消"
      :visible="visible"
      :confirm-loading="confirmLoading"
      @ok="exportOk"
      @cancel="exportCancel"
    >
      <p>确定导出？</p>
    </a-modal> -->
    <!-- 明细抽屉 -->
    <a-drawer
      :title="title"
      placement="right"
      width="800"
      :visible="visibleDrawer"
      :destroyOnClose="true"
      @close="onClose"
      class="site-modal"
    >
      <destribute
        :record="record"
        :distribution="distribution"
        @onClose="onClose"
        v-if="destributeShow"
        ref="Destribute"
      ></destribute>
      <MingXi v-if="MingXiShow"></MingXi>
    </a-drawer>
  </div>
</template>

<script>
import { SSearch, ColumnsSet } from "@/components";
import { GetWorkerAttend, CetAttendCalender } from "@/api/worker";
import { ExportWorkerAttend } from "@/api/export";
import MingXi from "./MingXi.vue";

// 搜索条件
const queryItems = [
  { label: "员工姓名", value: "w_name", type: "input" },
  { label: "工地名称", value: "ws_name", type: "input" },
  {
    label: "查询时间",
    value: "a_time",
    type: "dateRange",
    range: ["a_time_start", "a_time_end"],
  },
];

const columns = [
  {
    title: "序号",
    width: "70px",
    scopedSlots: { customRender: "serial" },
    dataIndex: "serial",
    align: "center",
  },
  {
    title: "工地名称",
    width: "100px",
    dataIndex: "ws_name",
    scopedSlots: { customRender: "ws_name" },
    ellipsis: true,
  },
  {
    title: "打卡天数",
    width: "90px",
    dataIndex: "w_post",
    scopedSlots: { customRender: "w_post" },
    align: "center",
  },
  {
    title: "累计时长",
    width: "90px",
    dataIndex: "w_type",
    scopedSlots: { customRender: "w_type" },
  },
  // 新增

  {
    title: "打卡明细",
    width: "90px",
    scopedSlots: { customRender: "action" },
    dataIndex: "action",
    align: "center",
  },
];
const orderParam = [
  "ws_name",
  "w_name",
  "w_type",
  // "a_time",
  "a_time_start",
  "a_time_end",
  "w_post",
  "w_jl_name",
  "w_gj_name",
  "attendance_type",
];

export default {
  name: "WorkerAttendStatistics",
  components: {
    SSearch,
    ColumnsSet,
    MingXi,
  },
  data() {
    this.queryItems = queryItems;
    this.columns = columns;
    return {
      tableColumns: [],
      sizeList: ["10", "20", "30", "40"], //一页能显示条数
      pageSize: 10, //当前页显示多少条
      current: 1, //当前页
      total: 0, //总条数,在获取后台数据时将数组的length赋值给total
      loading: false,
      dataSource: [],
      data: [],
      // 查询参数
      queryParam: {},
      calendarData: [],
      visible: false,
      confirmLoading: false,
      title: "",
      MingXiShow: false,
      destributeShow: false,
      visibleDrawer: false,
    };
  },

  created() {
    this.tableColumns = columns;
    this.changeColumns();
    if (this.$route.query) {
      this.queryParam.ws_name = this.$route.query.name;
      this.queryParam.a_time = this.$route.query.time;
    }
    this.dataLoad();
  },
  methods: {
    // 自定义表头列
    changeColumns() {
      let ColumnsSet = JSON.parse(
        localStorage.getItem("WorkerAttendColumnsSet")
      );
      let value = ColumnsSet ? ColumnsSet : [];
      let selectColumns = columns.filter((el) => value.includes(el.dataIndex));
      this.tableColumns = selectColumns.length ? selectColumns : columns;
    },
    //分页页数的改变
    pageNumberChange(current, size) {
      this.current = current;
      this.pageSize = size;
      this.getShowSource();
    },
    //  分页显示条数的变化
    sizeChange(current, size) {
      this.current = current;
      this.pageSize = size;
      this.getShowSource();
    },
    // 实现分页效果
    getShowSource() {
      const data = this.data;
      //后端返回的全部数据
      let keyValue = 0;
      this.total = data.length;
      for (let i = 0; i < this.total; i++) {
        keyValue = keyValue + 1;
        let key = { key: keyValue };
        data[i] = Object.assign(data[i], key);
      }
      this.loading = false;
      let start = this.pageSize * this.current - this.pageSize;
      let end = this.pageSize * this.current;
      let dataSource = data.slice(start, end);
      this.dataSource = dataSource;
      this.$set(this.dataSource, [], dataSource);
    },
    // 加载数据方法 必须为 Promise 对象
    dataLoad() {
      this.loading = true;
      const param = Object.assign({}, this.queryParam);
      const requestParameters = this.$order(param, orderParam);
      const params = {
        ...requestParameters,
        ws_status: "",
        d_status: "",
        ws_bind_status: "",
        // begin_time: "",
        // end_time: "",
        verify: this.$verify(requestParameters, orderParam),
      };
      GetWorkerAttend(params).then((res) => {
        this.data = res.data;
        this.getShowSource();
      });
    },
    //查询触发
    fetchUserAttend(value) {
      this.queryParam = value;
      this.dataLoad();
      // this.$refs.table.refresh(true);
    },
    //导出
    handleExport() {
      this.visible = true;
    },
    //确认导出
    exportOk() {
      this.confirmLoading = true;
      const params = {
        ws_name: this.queryParam.ws_name,
        w_name: this.queryParam.w_name,
        w_type: this.queryParam.w_type,
        a_time_start: this.queryParam.a_time_start,
        a_time_end: this.queryParam.a_time_end,
        w_post: this.queryParam.w_post,
        w_jl_name: this.queryParam.w_jl_name,
        w_gj_name: this.queryParam.w_gj_name,
      };

      ExportWorkerAttend(params).then((res) => {
        var blob = res;

        var reader = new FileReader();
        reader.readAsDataURL(blob); // 转换为base64，可以直接放入a标签href
        reader.onload = function (e) {
          // 转换完成，创建一个a标签用于下载
          var a = document.createElement("a");
          a.download = "人员考勤列表.xls";
          a.href = e.target.result;
          // $("body").append(a);    // 修复firefox中无法触发click
          a.click();
          a.remove();
        };

        this.visible = false;
        this.confirmLoading = false;
      });
    },
    //取消导出
    exportCancel() {
      this.visible = false;
    },
    // 明细按钮
    MingXi(temp, title, record) {
      this.visibleDrawer = true;
      console.log(temp, title);
      this.MingXiShow = true;
      this.title = title;
    },
    onClose(refresh) {
      this.visibleDrawer = false;
    },
  },
};
</script>
<style lang="less" scoped>
// 表头居中显示
/deep/.ant-table-thead > tr > th {
  text-align: center;
}
.StatisticsNum {
  text-align: left;
  font-size: 14px;
  font-weight: 600;
  .StatisticsNum_left {
    margin-left: 20px;
    margin-right: 5px;
  }
}
</style>
