<template>
  <div>
    <div class="gongdimingcheng">
      工地名称-太平洋比基尼海滩比奇堡镇贝壳接124号菠萝屋
    </div>
    <!-- 表格部分 -->
    <a-table
      :columns="columns"
      :data-source="data"
      :scroll="{ x: 1000 }"
      bordered
    >
      <span slot="action" slot-scope="text, record" class="action">
        <a-button
          type="primary"
          @click="JumpSubpage(record, 'SitePlayback', '回放')"
        >
          回放
        </a-button>
      </span>
    </a-table>
    <a-drawer
      :title="title"
      :width="800"
      placement="right"
      :visible="visible"
      :destroyOnClose="true"
      @close="onClose"
      class="site-modal"
    >
      <SitePlayback
        :record="record"
        v-if="SitePlayback"
        ref="SitePlayback"
      ></SitePlayback>
    </a-drawer>
  </div>
</template>
<script>
import SitePlayback from "../site/SitePlayback.vue";
const columns = [
  {
    title: "日期",
    dataIndex: "name",
    key: "name",
    // scopedSlots: { customRender: "name" },
    align: "center",
  },
  {
    title: "上班打卡时间",
    dataIndex: "age",
    key: "age",
    width: 100,
    align: "center",
  },
  {
    title: "上班打卡照片",
    dataIndex: "address",
    key: "address 1",
    align: "center",
  },
  {
    title: "下班打卡时间",
    dataIndex: "address1",
    key: "address 2",
    align: "center",
  },
  {
    title: "下班打卡照片",
    dataIndex: "address2",
    key: "address 3",
    align: "center",
  },
  {
    title: "工作时长",
    dataIndex: "address3",
    key: "address 4",
    align: "center",
  },
  {
    title: "录像回放",
    dataIndex: "address4",
    key: "address 5",
    scopedSlots: { customRender: "action" },
    dataIndex: "action",
    align: "center",
  },
];
const data = [
  {
    key: "1",
    name: "日期",
    age: "上班打卡时间",
    address: "上班打卡照片",
    address1: "下班打卡时间",
    address2: "下班打卡照片",
    address3: "工作时长",
    address4: "录像回放",
  },
];
export default {
  name: "MingXi",
  components: {
    SitePlayback,
  },
  data() {
    return {
      data,
      columns,
      SitePlayback: false,
      visible: false,
    };
  },
  methods: {
    // 回放按钮
    JumpSubpage(record, showChild, title, formType) {
      // 根据点击按钮不同，显示不同内容
      this.visible = true;
      this.title = "工地列表 - 工地" + title;
      this.record = record;
      this[showChild] = true;
      this.formType = formType;
      // console.log(this.record);
    },
    onClose() {
      // 关闭弹出框时的处理
      this.visible = false;
      this.SitePreview = false;
    },
  },
};
</script>
<style scoped>
div {
  background-color: #fff;
}
.gongdimingcheng {
  margin: 20px 10px;
  font-size: 20px;
  font-weight: 600;
}
</style>
